import { useMemo } from 'react'
import * as d3 from 'd3'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { formatNumber } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import { PrivacyField } from '@/components/PrivacyField'

type Item = {
  name: string
  value: number
  onNavigate?: () => void
}

const mockData: Item[] = [
  { name: 'MyCollectables', value: 5000 },
  { name: 'MyFinances', value: 3000 },
  { name: 'MyProperties', value: 2000 },
  { name: 'MyBelongings', value: 1000 }
]

interface ChartDescProps {
  data?: Item[]
  unit?: string
  formatter?: (value: string, unit: string) => string
  isValueInteger?: boolean
  thresholdRate?: number
}

export function getTopSix(items: Item[], name: string, thresholdRate?: number) {
  if (typeof thresholdRate === 'number') {
    const sortedData = [...items].sort((a, b) => b.value - a.value)
    const totalValue = sortedData.reduce((acc, cur) => acc + Math.abs(cur.value), 0)

    const threshold = totalValue * thresholdRate
    const significantItems = sortedData.filter((item) => Math.abs(item.value) >= threshold)
    const smallItems = sortedData.filter((item) => Math.abs(item.value) < threshold)

    if (significantItems.length > 7 || smallItems.length > 0) {
      const topItems = significantItems.slice(0, 6)
      const remainingItemsSum = significantItems.slice(6).reduce((acc, cur) => acc + cur.value, 0)
      const smallItemsSum = smallItems.reduce((acc, cur) => acc + cur.value, 0)
      const others = { name: t('Others'), value: remainingItemsSum + smallItemsSum }

      return [...topItems, others]
    }
    return significantItems
  }

  const sortedItems = [...items].sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
  const topSix = sortedItems.slice(0, 6)
  const others = sortedItems.slice(6)
  const othersValue = others.reduce((acc, cur) => acc + cur.value, 0)

  if (others.length > 0) {
    topSix.push({ name, value: othersValue })
  }
  return topSix
}

export function ChartDesc({
  data = [],
  unit = 'USD',
  formatter = (value, unit) => `${unit} ${value}`,
  isValueInteger = false,
  thresholdRate
}: ChartDescProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const isEmpty = data.length === 0
  const dataSource = getTopSix(data, t('Others'), thresholdRate)

  const colors = chartColors
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])

  const formatValue = (value: number) =>
    formatNumber(value, preferences.numberFormat, { digits: isValueInteger ? 0 : 2 })

  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'flex items-center justify-between text-sm'}>
        <span>{t('Name')}</span>
        <span>{t('ValueWithUnit', { unit })}</span>
      </div>
      <div className={'my-1 h-px w-full bg-grey'} />
      <div className={'flex min-h-[100px] flex-col gap-1'}>
        {isEmpty ? (
          <span className={'flex grow items-center justify-center font-bold'}>{t('NoData')}</span>
        ) : (
          dataSource.slice(0, 7).map((item, index) => (
            <div key={index} className={'flex gap-2'}>
              <div className={'my-0.5 h-4 w-4 rounded'} style={{ backgroundColor: colors[index % colors.length] }} />
              <div className={'relative flex min-w-0 flex-1 justify-between gap-1 text-sm'}>
                <TruncatedText className={'capitalize'}>{item.name}</TruncatedText>
                <span className={cn(item.value < 0 && 'text-error')}>
                  <PrivacyField className={'font-bold'}>{formatValue(item.value)}</PrivacyField>
                </span>
              </div>
            </div>
          ))
        )}
      </div>
      <div className={'my-1 h-px w-full bg-grey'} />
      <div className={'flex items-center justify-between text-sm font-bold'}>
        <span>{t('Total')}</span>
        <span className={cn(total < 0 && 'text-error')}>
          <PrivacyField className={'font-bold'}>{formatter(formatValue(total), unit)}</PrivacyField>
        </span>
      </div>
    </div>
  )
}

interface ChartLegendProps {
  className?: string
  data?: Item[]
  max?: number
  unassignedLabel?: string
}

export function ChartLegend({ className, data = [], max, unassignedLabel }: ChartLegendProps) {
  const { t } = useTranslation()
  const colors = chartColors
  const isEmpty = data.length === 0 && max === undefined
  const dataSource = data.map((item) => ({ ...item, value: Math.abs(item.value) })).sort((a, b) => b.value - a.value)
  const sum = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const hasUnassigned = max !== undefined && max > sum
  const unassigned: Item[] = hasUnassigned ? [{ name: unassignedLabel ?? t('Unassigned'), value: max - sum }] : []

  return isEmpty ? null : (
    <ul className={className}>
      {[...dataSource, ...unassigned].map((item, index) => {
        const isUnassigned = [t('Unassigned'), t('Unallocated')].includes(item.name)
        const isClickable = item.onNavigate !== undefined

        return (
          <li key={index} className={'flex max-w-[45%] items-center gap-x-2'}>
            <div
              className={'h-4 w-4 shrink-0 rounded'}
              style={{
                backgroundColor: !isUnassigned ? colors[index % colors.length] : emptyColors[0]
              }}
            />
            <p
              className={cn('truncate text-sm', isClickable && 'text-primary hover:cursor-pointer hover:underline')}
              onClick={item.onNavigate}
            >
              {item.name}
            </p>
          </li>
        )
      })}
    </ul>
  )
}
